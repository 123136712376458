import axios from "axios";

// const baseURL = "https://manuals.daimlertruck.com";
const baseURL = "https://digital-manuals.tsac-staging.daimlertruck.com";
// const baseURL = "http://localhost:7000/daimler/";

const apiClient = axios.create({
  baseURL: baseURL,
});

export default apiClient;
