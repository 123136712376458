import React from "react";
import { Divider, Stack, Paper, Box, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "./LandingPage.css";
import DesktopBreakPoint from "../../utils/responsive/DesktopBreakpoint";
import TabletBreakpoint from "../../utils/responsive/TabletBreakpoint";
import PhoneBreakpoint from "../../utils/responsive/PhoneBreakpoint";
import ClickAwayListener from '@mui/material/ClickAwayListener';

const LandingPage = ({ welcomeContent }) => {
  console.log(welcomeContent);
  var contentAsObject =
    welcomeContent &&
    welcomeContent?.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.Variable_Name]: item.translated_text }),
      {}
    );

  console.log("welcomeContent", contentAsObject);

  const [openTruckGuides, setOpenTruckGuides] = useState(false);

  const handleTooltipCloseTruckGuides = () => {
    setOpenTruckGuides(false);
  };

  const handleTooltipToggleTruckGuides = () => {
    setOpenTruckGuides((prevOpenTruckGuides)=>!prevOpenTruckGuides);
  };

  const [openEmergencyPortal, setOpenEmergencyPortal] = useState(false);

  const handleTooltipCloseEmergencyPortal = () => {
    setOpenEmergencyPortal(false);
  };

  const handleTooltipToggleEmergencyPortal = () => {
    setOpenEmergencyPortal((prevOpenEmergencyPortal)=>!prevOpenEmergencyPortal)
  };

  return (
    <div>
    <DesktopBreakPoint>
    <Container maxWidth="lg" style={{ color: "#fff" }}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{
          minHeight: {
            xs: "75vh",
            sm: "80vh",
            // md: "100vh",
          },
        }}
      >
        <Grid item xs={6} className="tile">
          <Box
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              disableRipple
              disableFocusRipple
              // disabled
              style={{ margin: "0 2px", width: "-webkit-fill-available" }}
              onClick={() =>
                window.open(
                  "https://digital-manuals.tsac-staging.daimlertruck.com/truckownermanuals/",
                  "_blank"
                )
              }
            >
              <img
                width="200"
                src={process.env.PUBLIC_URL + "/assets/images/TileImage2.png"}
                style={{
                  // display: "block",
                  // marginLeft: "auto",
                  // marginRight: "auto",
                  width: "40%",
                }}
                className="image-card"
                alt="Logo"
              />
            </IconButton>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tile-text">Truck Owners Manual</p>
            <Tooltip
              className="tooltip"
              title={
                contentAsObject?.$$welcome_text +
                " " +
                contentAsObject?.$$ownersManual_welcome_description
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 14,
                    fontFamily: "MBCorpoSText-Regular",
                    lineHeight: "1.25rem",
                    letterSpacing: ".0125em",
                    // bgcolor: "common.black",
                    // "& .MuiTooltip-arrow": {
                    //   color: "common.black",
                    // },
                  },
                },
              }}
              style={{
                fontSize: "20px",
                lineHeight: "1.25rem",
                letterSpacing: ".0125em",
              }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple
                disableFocusRipple
                // disabled
                style={{ margin: "0 2px" }}
              >
                <img
                  className="logo-img"
                  style={{ width: 25 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/QuestionmarkIcon.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={6} className="tile">
        <Box
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            disableRipple
            disableFocusRipple
            // disabled
            style={{ margin: "0 2px", width: "-webkit-fill-available" }}
            onClick={() =>
              window.open(
                "https://digital-manuals.tsac-staging.daimlertruck.com/emergencyportal/",
                "_blank"
              )
            }
          >
            <img
              width="200"
              src={process.env.PUBLIC_URL + "/assets/images/TileImage1.png"}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "40%",
              }}
              className="image-card"
              alt="Logo"
            />
          </IconButton>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tile-text">Emergency Portal</p>
            <Tooltip
              title={
                contentAsObject?.$$welcome_text +
                " " +
                contentAsObject?.$$emergencyPortal_welcome_description
              }
              className="tooltip"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 14,
                    fontFamily: "MBCorpoSText-Regular",
                    lineHeight: "1.25rem",
                    letterSpacing: ".0125em",
                    // bgcolor: "common.black",
                    // "& .MuiTooltip-arrow": {
                    //   color: "common.black",
                    // },
                  },
                },
              }}
              style={{ fontSize: "20px" }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple
                disableFocusRipple
                // disabled
                style={{ margin: "0 2px" }}
              >
                <img
                  className="logo-img"
                  style={{ width: 25 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/QuestionmarkIcon.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </DesktopBreakPoint>
    <TabletBreakpoint>
    <Container maxWidth="lg" style={{ color: "#fff" }}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{
          minHeight: {
            xs: "75vh",
            sm: "80vh",
            // md: "100vh",
          },
        }}
      >
        <Grid item xs={6} className="tile">
          <Box
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              disableRipple
              disableFocusRipple
              // disabled
              style={{ margin: "0 2px", width: "-webkit-fill-available" }}
              onClick={() =>
                window.open(
                  "https://digital-manuals.tsac-staging.daimlertruck.com/truckownermanuals/",
                  "_blank"
                )
              }
            >
              <img
                width="200"
                src={process.env.PUBLIC_URL + "/assets/images/TileImage2.png"}
                style={{
                  // display: "block",
                  // marginLeft: "auto",
                  // marginRight: "auto",
                  width: "40%",
                }}
                className="image-card"
                alt="Logo"
              />
            </IconButton>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tile-text">Truck Owners Manual</p>
            <ClickAwayListener onClickAway={handleTooltipCloseTruckGuides}>
            <Tooltip
              className="tooltip"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipCloseTruckGuides}
              open={openTruckGuides}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                contentAsObject?.$$welcome_text +
                " " +
                contentAsObject?.$$ownersManual_welcome_description
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 14,
                    fontFamily: "MBCorpoSText-Regular",
                    lineHeight: "1.25rem",
                    letterSpacing: ".0125em",
                    // bgcolor: "common.black",
                    // "& .MuiTooltip-arrow": {
                    //   color: "common.black",
                    // },
                  },
                },
              }}
              style={{ fontSize: "20px" }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple
                disableFocusRipple
                // disabled
                style={{ margin: "0 2px" }}
                onClick={handleTooltipToggleTruckGuides}
              >
                <img
                  className="logo-img"
                  style={{ width: 25 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/QuestionmarkIcon.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Tooltip>
            </ClickAwayListener>
          </Box>
        </Grid>
        <Grid item xs={6} className="tile">
        <Box
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            disableRipple
            disableFocusRipple
            // disabled
            style={{ margin: "0 2px", width: "-webkit-fill-available" }}
            onClick={() =>
              window.open(
                "https://digital-manuals.tsac-staging.daimlertruck.com/emergencyportal/",
                "_blank"
              )
            }
          >
            <img
              width="200"
              src={process.env.PUBLIC_URL + "/assets/images/TileImage1.png"}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "40%",
              }}
              className="image-card"
              alt="Logo"
            />
          </IconButton>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tile-text">Emergency Portal</p>
            <ClickAwayListener onClickAway={handleTooltipCloseEmergencyPortal}>
            <Tooltip
              className="tooltip"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipCloseEmergencyPortal}
              open={openEmergencyPortal}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                contentAsObject?.$$welcome_text +
                " " +
                contentAsObject?.$$emergencyPortal_welcome_description
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 14,
                    fontFamily: "MBCorpoSText-Regular",
                    lineHeight: "1.25rem",
                    letterSpacing: ".0125em",
                    // bgcolor: "common.black",
                    // "& .MuiTooltip-arrow": {
                    //   color: "common.black",
                    // },
                  },
                },
              }}
              style={{ fontSize: "20px" }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple
                disableFocusRipple
                // disabled
                style={{ margin: "0 2px" }}
                onClick={handleTooltipToggleEmergencyPortal}
              >
                <img
                  className="logo-img"
                  style={{ width: 25 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/QuestionmarkIcon.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Tooltip>
            </ClickAwayListener>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </TabletBreakpoint>
    <PhoneBreakpoint>
    <Container maxWidth="lg" style={{ color: "#fff" }}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{
          minHeight: {
            xs: "75vh",
            sm: "80vh",
            // md: "100vh",
          },
        }}
      >
        <Grid item xs={6} className="tile">
          <Box
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              disableRipple
              disableFocusRipple
              // disabled
              style={{ margin: "0 2px", width: "-webkit-fill-available" }}
              onClick={() =>
                window.open(
                  "https://digital-manuals.tsac-staging.daimlertruck.com/truckownermanuals/",
                  "_blank"
                )
              }
            >
              <img
                width="200"
                src={process.env.PUBLIC_URL + "/assets/images/TileImage2.png"}
                style={{
                  // display: "block",
                  // marginLeft: "auto",
                  // marginRight: "auto",
                  width: "40%",
                }}
                className="image-card"
                alt="Logo"
              />
            </IconButton>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tile-text">Truck Owners Manual</p>
            <ClickAwayListener onClickAway={handleTooltipCloseTruckGuides}>
            <Tooltip
              className="tooltip"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipCloseTruckGuides}
              open={openTruckGuides}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                contentAsObject?.$$welcome_text +
                " " +
                contentAsObject?.$$ownersManual_welcome_description
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 14,
                    fontFamily: "MBCorpoSText-Regular",
                    lineHeight: "1.25rem",
                    letterSpacing: ".0125em",
                    // bgcolor: "common.black",
                    // "& .MuiTooltip-arrow": {
                    //   color: "common.black",
                    // },
                  },
                },
              }}
              style={{ fontSize: "20px" }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple
                disableFocusRipple
                // disabled
                style={{ margin: "0 2px" }}
                onClick={handleTooltipToggleTruckGuides}
              >
                <img
                  className="logo-img"
                  style={{ width: 25 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/QuestionmarkIcon.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Tooltip>
            </ClickAwayListener>
          </Box>
        </Grid>
        <Grid item xs={6} className="tile">
        <Box
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            disableRipple
            disableFocusRipple
            // disabled
            style={{ margin: "0 2px", width: "-webkit-fill-available" }}
            onClick={() =>
              window.open(
                "https://digital-manuals.tsac-staging.daimlertruck.com/emergencyportal/",
                "_blank"
              )
            }
          >
            <img
              width="200"
              src={process.env.PUBLIC_URL + "/assets/images/TileImage1.png"}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "40%",
              }}
              className="image-card"
              alt="Logo"
            />
          </IconButton>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tile-text">Emergency Portal</p>
            <ClickAwayListener onClickAway={handleTooltipCloseEmergencyPortal}>
            <Tooltip
              className="tooltip"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipCloseEmergencyPortal}
              open={openEmergencyPortal}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                contentAsObject?.$$welcome_text +
                " " +
                contentAsObject?.$$emergencyPortal_welcome_description
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 14,
                    fontFamily: "MBCorpoSText-Regular",
                    lineHeight: "1.25rem",
                    letterSpacing: ".0125em",
                    // bgcolor: "common.black",
                    // "& .MuiTooltip-arrow": {
                    //   color: "common.black",
                    // },
                  },
                },
              }}
              style={{ fontSize: "20px" }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                disableRipple
                disableFocusRipple
                // disabled
                style={{ margin: "0 2px" }}
                onClick={handleTooltipToggleEmergencyPortal}
              >
                <img
                  className="logo-img"
                  style={{ width: 25 }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/QuestionmarkIcon.svg"
                  }
                  alt="Logo"
                />
              </IconButton>
            </Tooltip>
            </ClickAwayListener>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </PhoneBreakpoint>
    </div>
  );
};

export default LandingPage;
